<template>
  <div class="text-detail__body">
    <div class="her-2__banner">
      <picture>
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/banner_mob.jpg
          "
          media="(max-width: 767px)"
        />
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/banner_tab.jpg
          "
          media="(max-width: 1219px)"
        />
        <source
          srcset="
            https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/banner.jpg
          "
          media="(min-width: 1220px)"
        />
        <img
          src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/banner.jpg"
          class="her-2__banner-img"
        />
      </picture>
    </div>
    <div class="nf-other">
      <div class="nf-other__container mb-12">
        <div class="nf-other__tabs">
          <div
            class="nf-other__tabs-btn"
            @click="changeTab(0)"
            :class="{ active: activeTab === 0 }"
          >
            <div class="nf-other__tabs-btn_wrap">
              <div class="nf-other__tabs-icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="19.5" stroke="#5900C6" />
                  <g clip-path="url(#clip0_2744_2082)">
                    <path
                      d="M27.6133 22.2625C27.3657 22.2625 27.165 22.4631 27.165 22.7108V26.6911C27.1641 27.4336 26.5626 28.0353 25.8201 28.036H13.2415C12.499 28.0353 11.8975 27.4336 11.8966 26.6911V15.009C11.8975 14.2667 12.499 13.6651 13.2415 13.6642H17.2219C17.4695 13.6642 17.6702 13.4635 17.6702 13.2159C17.6702 12.9684 17.4695 12.7676 17.2219 12.7676H13.2415C12.0041 12.769 11.0014 13.7717 11 15.009V26.6913C11.0014 27.9286 12.0041 28.9313 13.2415 28.9328H25.8201C27.0575 28.9313 28.0602 27.9286 28.0616 26.6913V22.7108C28.0616 22.4631 27.8609 22.2625 27.6133 22.2625Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M27.8841 11.6143C27.0962 10.8265 25.8189 10.8265 25.0311 11.6143L17.0332 19.6122C16.9785 19.6669 16.9388 19.7349 16.9182 19.8095L15.8664 23.6066C15.8452 23.683 15.8447 23.7637 15.8648 23.8405C15.885 23.9172 15.9252 23.9872 15.9813 24.0433C16.0374 24.0994 16.1074 24.1396 16.1841 24.1598C16.2608 24.18 16.3416 24.1795 16.418 24.1583L20.2151 23.1064C20.2897 23.0857 20.3577 23.0461 20.4124 22.9914L28.41 14.9934C29.1967 14.205 29.1967 12.9288 28.41 12.1404L27.8841 11.6143ZM18.01 19.9036L24.5557 13.3578L26.6667 15.4688L20.1209 22.0146L18.01 19.9036ZM17.5883 20.7497L19.2748 22.4364L16.942 23.0828L17.5883 20.7497ZM27.7762 14.3594L27.3007 14.8348L25.1895 12.7236L25.6652 12.2482C26.1028 11.8106 26.8124 11.8106 27.2499 12.2482L27.7762 12.7742C28.2131 13.2124 28.2131 13.9215 27.7762 14.3594Z"
                      fill="#5900C6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2744_2082">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(11 11)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              Резюме
            </div>
          </div>
          <div
            class="nf-other__tabs-btn"
            @click="changeTab(1)"
            :class="{ active: activeTab === 1 }"
          >
            <div class="nf-other__tabs-btn_wrap">
              <div class="nf-other__tabs-icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="19.5" stroke="#5900C6" />
                  <path
                    d="M20.2312 13.8892C21.6598 13.8892 22.8179 12.7311 22.8179 11.3025C22.8179 9.87391 21.6598 8.71582 20.2312 8.71582C18.8026 8.71582 17.6445 9.87391 17.6445 11.3025C17.6445 12.7311 18.8026 13.8892 20.2312 13.8892Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M28.7248 22.853C30.1534 22.853 31.3115 21.6949 31.3115 20.2664C31.3115 18.8378 30.1534 17.6797 28.7248 17.6797C27.2963 17.6797 26.1382 18.8378 26.1382 20.2664C26.1382 21.6949 27.2963 22.853 28.7248 22.853Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M20.2312 31.2847C21.6598 31.2847 22.8179 30.1266 22.8179 28.698C22.8179 27.2694 21.6598 26.1113 20.2312 26.1113C18.8026 26.1113 17.6445 27.2694 17.6445 28.698C17.6445 30.1266 18.8026 31.2847 20.2312 31.2847Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M11.6272 27.4888C13.0558 27.4888 14.2139 26.3307 14.2139 24.9021C14.2139 23.4735 13.0558 22.3154 11.6272 22.3154C10.1986 22.3154 9.04053 23.4735 9.04053 24.9021C9.04053 26.3307 10.1986 27.4888 11.6272 27.4888Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M11.6272 17.3735C13.0558 17.3735 14.2139 16.2154 14.2139 14.7869C14.2139 13.3583 13.0558 12.2002 11.6272 12.2002C10.1986 12.2002 9.04053 13.3583 9.04053 14.7869C9.04053 16.2154 10.1986 17.3735 11.6272 17.3735Z"
                    stroke="#5900C6"
                  />
                  <path
                    d="M20.2266 21.3204C21.3802 21.3204 22.3155 20.3851 22.3155 19.2315C22.3155 18.0778 21.3802 17.1426 20.2266 17.1426C19.0729 17.1426 18.1377 18.0778 18.1377 19.2315C18.1377 20.3851 19.0729 21.3204 20.2266 21.3204Z"
                    stroke="#5900C6"
                  />
                  <path d="M13.8535 13.4665L17.7335 11.9688" stroke="#5900C6" />
                  <path d="M22.0444 13.1514L26.88 18.4536" stroke="#5900C6" />
                  <path d="M27.1424 22.3115L22.0757 26.8849" stroke="#5900C6" />
                  <path d="M13.0488 16.9463L18.6266 26.6707" stroke="#5900C6" />
                  <path d="M13.9155 26.1064L17.6444 27.8353" stroke="#5900C6" />
                  <path d="M12.5957 22.5023L18.8313 13.4756" stroke="#5900C6" />
                  <path d="M14.0093 15.7998L18.3737 18.2665" stroke="#5900C6" />
                  <path d="M22.3198 19.4668L26.1376 19.7557" stroke="#5900C6" />
                  <path d="M20.2266 21.3242L20.231 26.1064" stroke="#5900C6" />
                </svg>
              </div>
              <span class="d-none d-lg-inline"
                >Исследование DESTINY-Breast03</span
              >
              <span class="d-lg-none">Исследование DB03</span>
            </div>
          </div>
          <div
            class="nf-other__tabs-btn"
            @click="changeTab(2)"
            :class="{ active: activeTab === 2 }"
          >
            <div class="nf-other__tabs-btn_wrap">
              <div class="nf-other__tabs-icon">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="19.5" stroke="#5900C6" />
                  <g clip-path="url(#clip0_2744_2112)">
                    <path
                      d="M12.3055 25.2268C12.2839 25.2268 12.2618 25.2254 12.2393 25.2223C11.1913 25.0735 10.1775 24.7458 9.24329 24.2538C9.13832 24.1956 9.06061 24.0991 9.02682 23.985C8.99302 23.8709 9.00582 23.7482 9.06249 23.6434C9.11915 23.5385 9.21516 23.4596 9.32995 23.4238C9.44474 23.388 9.56918 23.3979 9.67661 23.4516C10.5164 23.8945 11.428 24.1892 12.3704 24.3224C12.485 24.3393 12.589 24.3983 12.6616 24.4876C12.7341 24.577 12.7699 24.69 12.7617 24.8042C12.7535 24.9184 12.702 25.0254 12.6174 25.1037C12.5328 25.1821 12.4215 25.226 12.3055 25.2268ZM28.6944 25.2268C28.578 25.2268 28.466 25.1832 28.3808 25.1049C28.2957 25.0266 28.2438 24.9193 28.2356 24.8047C28.2274 24.69 28.2634 24.5766 28.3365 24.4872C28.4096 24.3978 28.5143 24.3391 28.6295 24.3229C29.5719 24.1897 30.4836 23.895 31.3233 23.4521C31.4303 23.4023 31.5526 23.395 31.6648 23.4318C31.7771 23.4685 31.8708 23.5465 31.9264 23.6496C31.9821 23.7526 31.9955 23.8729 31.9639 23.9854C31.9323 24.098 31.8581 24.1942 31.7566 24.2543C30.8224 24.7462 29.8086 25.0739 28.7606 25.2227C28.7387 25.2254 28.7165 25.2268 28.6944 25.2268ZM23.8828 21.1491C23.7998 21.1493 23.7184 21.1273 23.6471 21.0855C23.5757 21.0437 23.5172 20.9836 23.4777 20.9116C23.4383 20.8395 23.4193 20.7583 23.4228 20.6764C23.4263 20.5946 23.4522 20.5153 23.4978 20.4468C23.8166 19.962 24.2532 19.5638 24.7678 19.2884C25.2825 19.013 25.8587 18.8692 26.4441 18.8701C26.5661 18.8701 26.6831 18.918 26.7693 19.0032C26.8556 19.0884 26.9041 19.2039 26.9041 19.3244C26.9041 19.4448 26.8556 19.5604 26.7693 19.6456C26.6831 19.7307 26.5661 19.7786 26.4441 19.7786C26.0119 19.7778 25.5863 19.8839 25.2063 20.0873C24.8263 20.2906 24.504 20.5848 24.2687 20.9428C24.227 21.0063 24.1698 21.0585 24.1024 21.0945C24.0349 21.1305 23.9595 21.1493 23.8828 21.1491Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M27.464 19.7785C25.8333 19.7785 24.4561 18.4185 24.4561 16.8087C24.4561 15.1989 25.8333 13.8389 27.464 13.8389C29.0942 13.8389 30.4715 15.1989 30.4715 16.8087C30.4715 18.4185 29.0942 19.7785 27.464 19.7785ZM27.464 14.7474C26.3324 14.7474 25.3761 15.6913 25.3761 16.8087C25.3761 17.9261 26.3324 18.8701 27.464 18.8701C28.5956 18.8701 29.5515 17.9261 29.5515 16.8087C29.5515 15.6913 28.5956 14.7474 27.464 14.7474Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M31.5401 22.7985C31.4181 22.7985 31.3011 22.7506 31.2148 22.6654C31.1286 22.5802 31.0801 22.4647 31.0801 22.3442C31.0793 21.6643 30.8054 21.0125 30.3185 20.5317C29.8316 20.0509 29.1715 19.7804 28.4829 19.7796C28.3609 19.7796 28.2439 19.7317 28.1577 19.6465C28.0714 19.5613 28.0229 19.4458 28.0229 19.3253C28.0229 19.2049 28.0714 19.0893 28.1577 19.0041C28.2439 18.919 28.3609 18.8711 28.4829 18.8711C30.4223 18.8711 32.0001 20.4296 32.0001 22.3442C32.0001 22.4647 31.9516 22.5802 31.8654 22.6654C31.7791 22.7506 31.6621 22.7985 31.5401 22.7985Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M28.4827 19.7786H26.4445C26.3225 19.7786 26.2055 19.7307 26.1192 19.6456C26.0329 19.5604 25.9845 19.4448 25.9845 19.3244C25.9845 19.2039 26.0329 19.0883 26.1192 19.0032C26.2055 18.918 26.3225 18.8701 26.4445 18.8701H28.4827C28.6047 18.8701 28.7217 18.918 28.808 19.0032C28.8943 19.0883 28.9427 19.2039 28.9427 19.3244C28.9427 19.4448 28.8943 19.5604 28.808 19.6456C28.7217 19.7307 28.6047 19.7786 28.4827 19.7786ZM31.5399 24.3074C31.4179 24.3074 31.3009 24.2595 31.2146 24.1744C31.1283 24.0892 31.0799 23.9736 31.0799 23.8532V22.3437C31.0799 22.2232 31.1283 22.1077 31.2146 22.0225C31.3009 21.9373 31.4179 21.8895 31.5399 21.8895C31.6619 21.8895 31.7789 21.9373 31.8652 22.0225C31.9514 22.1077 31.9999 22.2232 31.9999 22.3437V23.8532C31.9999 23.9736 31.9514 24.0892 31.8652 24.1744C31.7789 24.2595 31.6619 24.3074 31.5399 24.3074ZM15.065 24.6426C14.943 24.6426 14.826 24.5948 14.7397 24.5096C14.6534 24.4244 14.605 24.3089 14.605 24.1884C14.605 21.7182 16.64 19.7091 19.141 19.7091C19.263 19.7091 19.38 19.757 19.4663 19.8421C19.5526 19.9273 19.601 20.0429 19.601 20.1633C19.601 20.2838 19.5526 20.3994 19.4663 20.4845C19.38 20.5697 19.263 20.6176 19.141 20.6176C17.1469 20.6176 15.525 22.2192 15.525 24.1884C15.525 24.3089 15.4765 24.4244 15.3902 24.5096C15.304 24.5948 15.187 24.6426 15.065 24.6426Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M21.8587 20.6176H19.141C19.019 20.6176 18.902 20.5697 18.8157 20.4845C18.7294 20.3994 18.681 20.2838 18.681 20.1634C18.681 20.0429 18.7294 19.9273 18.8157 19.8422C18.902 19.757 19.019 19.7091 19.141 19.7091H21.8587C21.9807 19.7091 22.0977 19.757 22.1839 19.8422C22.2702 19.9273 22.3187 20.0429 22.3187 20.1634C22.3187 20.2838 22.2702 20.3994 22.1839 20.4845C22.0977 20.5697 21.9807 20.6176 21.8587 20.6176ZM17.117 21.1491C17.0403 21.1491 16.9649 21.1303 16.8975 21.0943C16.8301 21.0583 16.7729 21.0062 16.731 20.9428C16.4957 20.5849 16.1733 20.2908 15.7934 20.0874C15.4134 19.8841 14.9879 19.7779 14.5557 19.7786C14.4337 19.7786 14.3167 19.7307 14.2304 19.6456C14.1442 19.5604 14.0957 19.4448 14.0957 19.3244C14.0957 19.2039 14.1442 19.0884 14.2304 19.0032C14.3167 18.918 14.4337 18.8701 14.5557 18.8701C15.1411 18.8691 15.7174 19.0128 16.232 19.2883C16.7467 19.5637 17.1833 19.962 17.502 20.4468C17.5472 20.5153 17.5729 20.5946 17.5762 20.6763C17.5796 20.7581 17.5606 20.8392 17.5211 20.9111C17.4817 20.983 17.4233 21.0431 17.3522 21.085C17.2811 21.1268 17.1998 21.149 17.117 21.1491ZM15.0649 26.6554C14.9429 26.6554 14.8259 26.6075 14.7397 26.5223C14.6534 26.4372 14.6049 26.3216 14.6049 26.2011V24.1884C14.6049 24.0679 14.6534 23.9524 14.7397 23.8672C14.8259 23.782 14.9429 23.7342 15.0649 23.7342C15.1869 23.7342 15.3039 23.782 15.3902 23.8672C15.4765 23.9524 15.5249 24.0679 15.5249 24.1884V26.2011C15.5249 26.3216 15.4765 26.4372 15.3902 26.5223C15.3039 26.6075 15.1869 26.6554 15.0649 26.6554Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M20.5002 20.6176C18.4095 20.6176 16.6431 18.8734 16.6431 16.8088C16.6431 14.7443 18.4095 13 20.5002 13C22.5909 13 24.3573 14.7443 24.3573 16.8088C24.3573 18.8734 22.5909 20.6176 20.5002 20.6176ZM20.5002 13.9085C18.9081 13.9085 17.5631 15.2367 17.5631 16.8088C17.5631 18.381 18.9081 19.7092 20.5002 19.7092C22.0922 19.7092 23.4373 18.381 23.4373 16.8088C23.4373 15.2367 22.0922 13.9085 20.5002 13.9085Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M25.935 24.6425C25.813 24.6425 25.696 24.5947 25.6097 24.5095C25.5235 24.4243 25.475 24.3087 25.475 24.1883C25.475 22.2191 23.853 20.6175 21.8589 20.6175C21.7369 20.6175 21.6199 20.5696 21.5337 20.4844C21.4474 20.3992 21.3989 20.2837 21.3989 20.1632C21.3989 20.0428 21.4474 19.9272 21.5337 19.842C21.6199 19.7568 21.7369 19.709 21.8589 19.709C24.3604 19.709 26.395 21.7186 26.395 24.1883C26.395 24.3087 26.3465 24.4243 26.2603 24.5095C26.174 24.5947 26.057 24.6425 25.935 24.6425Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M25.9351 26.6556C25.8131 26.6556 25.6961 26.6078 25.6098 26.5226C25.5236 26.4374 25.4751 26.3218 25.4751 26.2014V24.1886C25.4751 24.0681 25.5236 23.9526 25.6098 23.8674C25.6961 23.7822 25.8131 23.7344 25.9351 23.7344C26.0571 23.7344 26.1741 23.7822 26.2604 23.8674C26.3466 23.9526 26.3951 24.0681 26.3951 24.1886V26.2014C26.3951 26.3218 26.3466 26.4374 26.2604 26.5226C26.1741 26.6078 26.0571 26.6556 25.9351 26.6556Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M20.5002 27.9999C18.559 27.9999 16.6183 27.5338 14.8487 26.6022C14.7472 26.5422 14.673 26.4459 14.6414 26.3334C14.6098 26.2208 14.6232 26.1006 14.6788 25.9975C14.7345 25.8944 14.8282 25.8165 14.9404 25.7797C15.0527 25.7429 15.175 25.7502 15.282 25.8C16.8875 26.6475 18.6799 27.0908 20.5005 27.0908C22.321 27.0908 24.1134 26.6475 25.7189 25.8C25.8259 25.7502 25.9482 25.7429 26.0605 25.7797C26.1728 25.8165 26.2664 25.8944 26.3221 25.9975C26.3778 26.1006 26.3912 26.2208 26.3596 26.3334C26.3279 26.4459 26.2537 26.5422 26.1522 26.6022C24.413 27.5191 22.4718 27.9991 20.5002 27.9999ZM13.5363 19.7785C11.906 19.7785 10.5288 18.4185 10.5288 16.8087C10.5288 15.1989 11.906 13.8389 13.5363 13.8389C15.1665 13.8389 16.5438 15.1989 16.5438 16.8087C16.5438 18.4185 15.1665 19.7785 13.5363 19.7785ZM13.5363 14.7474C12.4047 14.7474 11.4488 15.6913 11.4488 16.8087C11.4488 17.9261 12.4047 18.8701 13.5363 18.8701C14.6679 18.8701 15.6238 17.9261 15.6238 16.8087C15.6238 15.6913 14.6679 14.7474 13.5363 14.7474Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M9.46 22.7985C9.338 22.7985 9.221 22.7506 9.13473 22.6654C9.04846 22.5802 9 22.4647 9 22.3442C9 20.4291 10.5783 18.8711 12.5172 18.8711C12.6392 18.8711 12.7562 18.919 12.8424 19.0041C12.9287 19.0893 12.9772 19.2049 12.9772 19.3253C12.9772 19.4458 12.9287 19.5613 12.8424 19.6465C12.7562 19.7317 12.6392 19.7796 12.5172 19.7796C11.8285 19.7802 11.1683 20.0506 10.6814 20.5314C10.1944 21.0122 9.92061 21.6642 9.92 22.3442C9.92 22.4647 9.87154 22.5802 9.78527 22.6654C9.699 22.7506 9.582 22.7985 9.46 22.7985Z"
                      fill="#5900C6"
                    />
                    <path
                      d="M14.5554 19.7786H12.5172C12.3952 19.7786 12.2782 19.7307 12.1919 19.6456C12.1056 19.5604 12.0572 19.4448 12.0572 19.3244C12.0572 19.2039 12.1056 19.0883 12.1919 19.0032C12.2782 18.918 12.3952 18.8701 12.5172 18.8701H14.5554C14.6774 18.8701 14.7944 18.918 14.8807 19.0032C14.967 19.0883 15.0154 19.2039 15.0154 19.3244C15.0154 19.4448 14.967 19.5604 14.8807 19.6456C14.7944 19.7307 14.6774 19.7786 14.5554 19.7786ZM9.46 24.3074C9.338 24.3074 9.221 24.2595 9.13473 24.1744C9.04846 24.0892 9 23.9736 9 23.8532V22.3437C9 22.2232 9.04846 22.1077 9.13473 22.0225C9.221 21.9373 9.338 21.8895 9.46 21.8895C9.582 21.8895 9.699 21.9373 9.78527 22.0225C9.87154 22.1077 9.92 22.2232 9.92 22.3437V23.8532C9.92 23.9736 9.87154 24.0892 9.78527 24.1744C9.699 24.2595 9.582 24.3074 9.46 24.3074Z"
                      fill="#5900C6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2744_2112">
                      <rect
                        width="23"
                        height="15"
                        fill="white"
                        transform="translate(9 13)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              Различные подгруппы
            </div>
          </div>
        </div>
        <div class="nf-other__content">
          <div class="nf-other__content-block" v-show="activeTab === 0">
            <div class="nf-other__content-section">
              <div class="text-1 mb-4">
                Результаты исследования DESTINY-Breast03
                <b style="color: #ff7b25"
                  >изменили подход ко второй линии терапии HER2+ мРМЖ</b
                ><sup>1</sup>. В группе Энхерту (трастузумаб дерукстекан) было
                показано
                <b style="color: #ff7b25">значимое увеличение эффективности</b>
                лечения по сравнению с группой трастузумаба эмтанзина (T‑DM1) у
                пациенток с HER2+ мРМЖ, ранее получивших
                <b style="color: #ff7b25"
                  >не менее 1 режима анти-HER2 терапии:</b
                ><sup>1</sup>
              </div>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/1_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/1_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/1.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/1.jpg"
                />
              </picture>
              <div class="text-1 mb-4">
                Терапия Энхерту включена в международные и российские
                рекомендации в качестве второй линии терапии пациентов с HER2+
                мРМЖ<sup>2-4</sup>:
              </div>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/2_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/2_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/2.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/2.jpg"
                />
              </picture>
              <div class="text-1">
                Препарату Энхерту FDA присвоило статус «терапии прорыва» для
                пациентов с HER2+ мРМЖ во второй линии<sup>*5</sup>:
              </div>
            </div>
            <div class="text-1 mb-8" style="color: #000">
              <sup>*</sup>Для HER2-положительных, ранее получивших не менее 1
              режима анти-HER2 терапии.
            </div>
            <div class="text-3 mb-4" style="color: #000">
              Список сокращений и условных обозначений:
            </div>
            <div class="text-1 mb-8">
              HER2 – рецептор эпидермального фактора роста человека 2-го типа
              (human epidermal growth factor receptor 2);
              <br />
              <br />
              мРМЖ – метастатический рак молочной железы;
              <br />
              <br />
              мВБП – медиана выживаемости без прогрессирования;
              <br />
              <br />
              ЧОО – частота объективного ответа;
              <br />
              <br />
              мОВ – медиана общей выживаемости;
              <br />
              <br />
              FDA — Управление по санитарному надзору за качеством пищевых
              продуктов и медикаментов;
              <br />
              <br />
              NCCN — комплексная сеть по борьбе с онкологическими заболеваниями
              (США);
              <br />
              <br />
              ESMO — Европейское Общество медицинской онкологии;
              <br />
              <br />
              РООМ — Российское общество онкомаммологов.
              <br />
              <br />
              T-DM1 — трастузумаб эмтанзин.
            </div>
            <div class="above-title-1 mb-4" style="color: #830051">
              Источники
            </div>
            <ol style="list-style-position: inside; padding-left: 0">
              <li>
                Hurvitz S et al. The Lancet. 2023. doi:
                10.1016/S0140-6736(22)02420-5;
              </li>
              <li>
                NCCN Clinical Practice Guidelines in Oncology
                (NCCNGuidelines<sup>®</sup>). Breast Cancer. Version 4.2023 -
                March 23, 2023, Электронный ресурс:
                <a
                  style="color: inherit; line-break: anywhere"
                  href="https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf"
                  target="_blank"
                  >https://www.nccn.org/professionals/physician_gls/pdf/breast.pdf</a
                >, дата обращения: 25.05.2023;
              </li>
              <li>
                ESMO Metastatic Breast Cancer Living Guidelines, v1.1 May 2023,
                Электронный ресурс:
                <a
                  style="color: inherit; line-break: anywhere"
                  href="https://www.esmo.org/living-guidelines/esmo-metastatic-breast-cancer-living-guideline"
                  target="_blank"
                  >https://www.esmo.org/living-guidelines/esmo-metastatic-breast-cancer-living-guideline</a
                >, дата обращения: 25.05.2023;
              </li>
              <li>
                Клинические рекомендации Российского общества онкомаммологов
                «Золотой стандарт профилактики, диагностики, лечения и
                реабилитации больных РМЖ», Электронный ресурс:
                <a
                  style="color: inherit; line-break: anywhere"
                  href="https://docs.google.com/document/d/14XU19rSHmmg0v8aL9msY9B1uoscVK7WS/edit#"
                  target="_blank"
                  >https://docs.google.com/document/d/14XU19rSHmmg0v8aL9msY9B1uoscVK7WS/edit#</a
                >
                , дата обращения: 25.05.2023;
              </li>
              <li>
                Электронный ресурс
                <a
                  style="color: inherit; line-break: anywhere"
                  href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-grants-regular-approval-fam-trastuzumab-deruxtecan-nxki-breast-cancer"
                  target="_blank"
                  >https://www.fda.gov/drugs/resources-information-approved-drugs/fda-grants-regular-approval-fam-trastuzumab-deruxtecan-nxki-breast-cancer</a
                >, дата доступа: 21.12.2023.
              </li>
            </ol>
          </div>
          <div class="nf-other__content-block" v-show="activeTab === 1">
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f"
                ><span style="color: #ff7b25">DESTINY-Breast03</span>:
                рандомизированное открытое многоцентровое исследование<sup
                  >1</sup
                ></div
              >
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/3_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/3_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/3.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/3.jpg"
                />
              </picture>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f"
                >Выживаемость без прогрессирования<sup>1</sup></div
              >
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1">
                <ul>
                  <li>
                    Медиана ВБП в группе Энхерту
                    <span style="color: #ff7b25">была в четыре раза выше,</span>
                    чем а группе T-DM1, и составила
                    <span style="color: #ff7b25"
                      >почти 2,5 года: 28,8 мес.</span
                    >
                    по сравнению с 6,8 мес.
                  </li>
                  <li>
                    Терапия Энхерту позволила
                    <span style="color: #ff7b25"
                      >снизить риск прогрессирования болезни на 67%</span
                    >
                    по сравнению с терапией T-DM1.
                  </li>
                </ul>
              </div>
                <b
                class="text-1 pa-4 d-block"
                style="background-color: #fff; text-align: center"
                >Выживаемость без прогрессирования на фоне терапии</b
              >
                <div class="overflow-image">
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/4.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/4_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/4.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/4.jpg"
                />
              </picture>
                </div>
                
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f"
                >Частота объективного ответа<sup>1</sup></div
              >
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1">
                <ul>
                  <li>
                    ЧОО в группе Энхерту
                    <span style="color: #ff7b25">в 2 раза превысила</span>
                    показатель в группе T-DM1 и составила
                    <span style="color: #ff7b25">78,5%</span>
                  </li>
                  <li>
                    <span style="color: #ff7b25"
                      >Контроль над заболеванием</span
                    >
                    у пациентов в группе Энхерту составил
                    <span style="color: #ff7b25">96,6%</span>
                  </li>
                  <li>
                    <span style="color: #ff7b25"
                      >У каждого пятого пациента</span
                    >
                    на терапии Энхерту наблюдался
                    <span style="color: #ff7b25">полный ответ</span>
                  </li>
                </ul>
              </div>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/5_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/5_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/5.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/5.jpg"
                />
              </picture>
            </div>
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">Отдаленные результаты</div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <div class="text-1">
                Терапия Энхерту позволила значимо улучшить отдаленные результаты
                лечения.<sup>1</sup><br />
                <b class="d-block my-3"
                  >Медиана выживаемости без второго прогрессирования<sup
                    >1</sup
                  ></b
                >
                <ul class="mb-4">
                  <li>
                    Энхерту значимо увеличил время до прогрессирования при
                    применении терапии следующей линии.
                  </li>
                  <li>
                    В группе терапии Энхерту медиана выживаемости без второго
                    прогрессирования (мВБП2) была выше более чем в 1,5 раза по
                    сравнению с терапией T-DM1.
                  </li>
                </ul>
                мВБП2 в группах сравнения<br /><br />
                Из пациентов, досрочно прекративших исследуемую терапию, после
                завершения исследования:<br />
                в группе Энхерту 64/182 (35,2 %) получали T-DM1 <br />
                в группе T-DM1 42/243 (17,3 %) получали T-DXd
                <br /><br />
              </div>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/6_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/6_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/6.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/6.jpg"
                />
              </picture>
              <div class="text-1 mb-2">
                <b>Общая выживаемость<sup>1</sup></b>
              </div>
              <ul>
                <li>
                  На момент анализа данных мОВ в группе Энхерту превысила
                  значение в группе T-DM1, продемонстрировав снижение риска
                  смерти на 36%.
                </li>
                <li>В обеих группах мОВ не была достигнута.</li>
              </ul>
               <b
                class="text-1 pa-4 d-block"
                style="background-color: #fff; text-align: center"
                >мОВ в группах сравнения</b
              >
                <div class="overflow-image">
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/7.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/7_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/7.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/7.jpg"
                />
              </picture>
                </div>
                <div
                class="text-1 pa-4 d-block"
                > <sup>d</sup>P-значение для общей выживаемости пересекало предварительно установленную границу (P = 0,013) и было статистически значимым. <br>
<sup>e</sup>Двустороннее значение.</div
              >
            </div>
            <div class="text-3 mb-4" style="color: #000">
              Список сокращений и условных обозначений:
            </div>
            <div class="text-1 mb-8">
              HER2 – рецептор эпидермального фактора роста человека 2-го типа
              (human epidermal growth factor receptor 2);
              <br /><br />
              мРМЖ – метастатический рак молочной железы; <br /><br />
              T-DM1 — трастузумаб эмтанзин; <br /><br />
              ВБП — выживаемость без прогрессирования; <br /><br />
              ЧОО — частота объективного ответа на лечение; <br /><br />
              ОВ — общая выживаемость; <br /><br />
              ПрО — продолжительность ответа; <br /><br />
              BICR — независимая централизованная оценка в слепом режиме;
              <br /><br />
              ИГХ — иммуногистохимическое исследование; <br /><br />
              ISH — гибридизация in situ; <br /><br />
              1 р/3 нед — один раз в три недели; <br /><br />
              мВБП – медиана выживаемости без прогрессирования; <br /><br />
              мес. – месяцы; <br /><br />
              ДИ - доверительный интервал; <br /><br />
              ОР – отношение рисков; <br /><br />
              T-DXd – трастузумаб дерукстекан; <br /><br />
              ПО — полный ответ; <br /><br />
              ЧО — частичный ответ; <br /><br />
              СЗ — стабилизация заболевания; <br /><br />
              ПЗ — прогрессирование заболевания; <br /><br />
              Н/О — не поддается оценке; <br /><br />
              мПрО — медиана продолжительности ответа; <br /><br />
              мВБП2 – медиана выживаемости без второго прогрессирования;
              <br /><br />
              мОВ - медиана общей выживаемости.
            </div>
            <div class="above-title-1 mb-4" style="color: #830051">
              Источники
            </div>
            <ol style="list-style-position: inside; padding-left: 0">
              <li>
                Hurvitz S et al. The Lancet. 2023. doi:
                10.1016/S0140-6736(22)02420-5.
              </li>
            </ol>
          </div>
          <div class="nf-other__content-block" v-show="activeTab === 2">
            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f"
                ><span style="color: #ff7b25">ЭНХЕРТУ</span>: продемонстрировал
                эффективность во второй линии терапии HER2+ мРМЖ вне зависимости
                от:<sup>1-4</sup></div
              >
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <ul>
                <li>
                  Медиана ВБП в группе Энхерту
                  <span style="color: #ff7b25">была в четыре раза выше</span>,
                  чем а группе T-DM1, и составила
                  <span style="color: #ff7b25">почти 2,5 года: 28,8 мес</span>.
                  по сравнению с 6,8 мес.
                </li>
                <li>
                  Терапия Энхерту позволила
                  <span style="color: #ff7b25"
                    >снизить риск прогрессирования болезни на 67%</span
                  >
                  по сравнению с терапией T-DM1.
                </li>
              </ul>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/8_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/8_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/8.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/8.jpg"
                />
              </picture>
            </div>

            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">Пациенты с метастазами в ГМ</div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <ul>
                <li>
                  <b style="color: #ff7b25">мВБПБ</b> в группе Энхерту у
                  пациентов с метастазами в ГМ была
                  <b style="color: #ff7b25">в 5 раз выше</b>, чем в группе T-DM1
                  и составила > 1 года<sup>3*</sup>
                </li>
              </ul>
              <b
                class="text-1 pa-4 d-block"
                style="background-color: #fff; text-align: center"
                >ВБП у пациентов с метастазами в головной мозг исходно</b
              >
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/9.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/9_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/9.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/9.jpg"
                  />
                </picture>
              </div>
              <ul class="mb-4">
                <li>
                  <b style="color: #ff7b25">ЧОО</b> у пациентов с метастазами в
                  ГМ в группе Энхерту
                  <b style="color: #ff7b25">в 3 раза выше</b>, чем в группе
                  T-DM1. У <b style="color: #ff7b25">почти 5%</b> пациентов в
                  группе Энхерту наблюдался
                  <b style="color: #ff7b25">полный ответ<sup>2-3*</sup></b>
                </li>
              </ul>
              <picture>
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/10_mob.jpg
                  "
                  media="(max-width: 767px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/10_tab.jpg
                  "
                  media="(max-width: 1219px)"
                />
                <source
                  srcset="
                    https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/10.jpg
                  "
                  media="(min-width: 1220px)"
                />
                <img
                  class="mb-4"
                  src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/10.jpg"
                />
              </picture>
              <ul>
                <li>
                  мОВ в группе Энхерту у пациентов с метастазами в ГМ превысила
                  значение в группе T-DM1, продемонстрировав
                  <b style="color: #ff7b25"
                    >снижение риска смерти на 46%<sup>1**</sup></b
                  >
                </li>
              </ul>
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/11.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/11_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/11.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/11.jpg"
                  />
                </picture>
              </div>
              <ul class="mb-4">
                <li>
                  <b style="color: #ff7b25"
                    >Частота объективного интракраниального ответа</b
                  >
                  в группе Энхерту практически
                  <b style="color: #ff7b25">в 2 раза выше</b> по сравнению с
                  группой T-DM1<sup>3*</sup>
                </li>
              </ul>
              <div class="overflow-image">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/12.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/12_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/12.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/12.jpg"
                  />
                </picture>
              </div>
            </div>

            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">Пациенты с висцеральными метастазами</div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <ul>
                <li>
                  <b style="color: #ff7b25">мВБП</b> в группе Энхерту у
                  пациентов с висцеральными метастазами была сопоставима с общей
                  популяцией и в
                  <b style="color: #ff7b25">4 раза превысила показатель</b> на
                  T-DM1<sup>1**</sup>
                </li>
              </ul>
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/13.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/13_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/13.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/13.jpg"
                  />
                </picture>
              </div>
              <ul class="mb-4">
                <li>
                  мОВ в группе Энхерту у пациентов с висцеральными метастазами
                  была сопоставима с общей популяцией, продемонстрировав
                  снижение риска смерти на
                  <b style="color: #ff7b25">32%<sup>1**</sup></b>
                </li>
              </ul>
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/14.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/14_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/14.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/14.jpg"
                  />
                </picture>
              </div>
            </div>

            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">Статус гормональных рецепторов</div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <ul>
                <li>
                  мВБП в группе ЭНХЕРТУ превысила показатель на T-DM1
                  <b style="color: #ff7b25"
                    >вне зависимости от статуса гормональных рецепторов<sup
                      >1**</sup
                    ></b
                  >
                </li>
              </ul>
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/15.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/15_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/15.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/15.jpg"
                  />
                </picture>
              </div>
              <ul class="mb-4">
                <li>
                  Энхерту продемонстрировал снижение риска смерти
                  <b style="color: #ff7b25"
                    >вне зависимости от статуса гормональных рецепторов<sup
                      >1**</sup
                    ></b
                  >
                </li>
              </ul>
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/16.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/16_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/16.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/16.jpg"
                  />
                </picture>
              </div>
            </div>

            <div
              class="pt-3 pb-4 px-4 title-4"
              style="background-color: #ffebde; border-bottom: 2px solid #fff"
            >
              <div style="color: #1f1f1f">Возраст пациентов</div>
            </div>
            <div
              class="nf-other__content-section nf-other__content-section-light"
            >
              <ul>
                <li>
                  Энхерту продемонстрировал большую эффективность второй линии
                  терапии HER2+ мРМЖ
                  <b style="color: #ff7b25"
                    >вне зависимости от возраста пациентов<sup>4**</sup></b
                  >
                </li>
              </ul>
              <div class="overflow-image mb-4">
                <picture>
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/18.jpg
                    "
                    media="(max-width: 767px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/18_tab.jpg
                    "
                    media="(max-width: 1219px)"
                  />
                  <source
                    srcset="
                      https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/18.jpg
                    "
                    media="(min-width: 1220px)"
                  />
                  <img
                    class="mb-4"
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/enhertu/her-2/18.jpg"
                  />
                </picture>
              </div>
            </div>

            <div class="text-1 mb-4">
              *Дата среза данных 21 мая 2021 г.<br />
              **Дата среза данных 25 июля 2022 г.
            </div>
            <div class="text-3 mb-4" style="color: #000">
              Список сокращений и условных обозначений:
            </div>
            <div class="text-1 mb-8">
              HER2 – рецептор эпидермального фактора роста человека 2-го типа
              (human epidermal growth factor receptor 2);<br /><br />
              мРМЖ – метастатический рак молочной железы;<br /><br />
              ГМ – головной мозг;<br /><br />
              мВБП – медиана выживаемости без прогрессирования;<br /><br />
              T-DM1 – трастузумаб эмтанзин<br /><br />
              мес. – месяцы;<br /><br />
              ДИ - доверительный интервал;<br /><br />
              ОР – отношение рисков;<br /><br />
              T-DXd – трастузумаб дерукстекан.<br /><br />
              ЧОО — частота объективного ответа на лечение; <br /><br />
              мОВ — медиана общей выживаемости; <br /><br />
              ПрО — продолжительность ответа; <br /><br />
              ПО — полный ответ; <br /><br />
              ЧО — частичный ответ; <br /><br />
              СЗ — стабилизация заболевания; <br /><br />
              ПЗ — прогрессирование заболевания; <br /><br />
              Н/О — не поддается оценке; <br /><br />
              НД — не достигнуто; <br /><br />
              ЧКЗ — частота клинической значимости; <br /><br />
              иЧОО — интракраниальная частота объективного ответа.
            </div>
            <div class="above-title-1 mb-4" style="color: #830051">
              Источники
            </div>
            <ol style="list-style-position: inside; padding-left: 0">
              <li>
                1. Hurvitz S et al. The Lancet. 2023. doi:
                10.1016/S0140-6736(22)02420-5. 2. Cortés J et al. Presented at:
                ESMO Virtual Congress 2021; September 16–21, 2021. Presentation
                2525. 3. Hurvitz SA et al. Presented at: San Antonio Breast
                Cancer Symposium 2021; December 7–10, 2021. Presentation GS3–01.
                4. Krop I et al. Presented at: American Society of Clinical
                Oncology Annual Meeting 2023; June 2–6, 2023. Presentation 1006.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Her2_content",
  data: () => ({
    activeTab: 0,
  }),
  methods: {
    changeTab(index) {
      if (
        this.user &&
        !Object.keys(this.user).length &&
        !this.$route.query.access
      ) {
        this.popup = true;
      } else {
        this.activeTab = index;
      }
    },
    scrollToTabTopAndChangeTab(index) {
      this.changeTab(index);
      if (
        (this.user && Object.keys(this.user).length) ||
        this.$route.query.access
      ) {
        setTimeout(() => {
          const tabBtn = document.querySelector(
            `.nf-other__tabs-btn:nth-child(${index + 1})`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
        }, 100); // Задержка для обеспечения корректной смены таба перед прокруткой
      }
    },
    getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail__body {
  // margin-top: 64px;
  @media screen and (max-width: 1219px) {
    // margin-top: 32px;
  }
}

.overflow-image {
  @media screen and (max-width: 767px) {
    padding-bottom: 15px;
    overflow: auto;
    // -ms-overflow-style: scrollbar;
    // scrollbar-color: #d2d2d2 transparent;
    // scrollbar-width: thin;
    background-color: #fff;

    & img {
      width: 200%;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d2d2d2;
      border-radius: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar--track-piece {
      border-radius: 5px;
      background-color: #ebefee;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #ebefee;
      border: 3px solid #ebefee;
    }

    &::-moz-scrollbar-button:decrement,
    &::-moz-scrollbar-button:increment,
    &::-moz-scrollbar-button:vertical:start:decrement,
    &::-moz-scrollbar-button:vertical:end:increment,
    &::-moz-scrollbar-button:horizontal:start:decrement,
    &::-moz-scrollbar-button:horizontal:end:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
      width: 0px !important;
      height: 0px !important;
    }
  }
}

.her-2__banner-img {
  width: 100%;
  margin-bottom: 32px;
}
.nf-other {
  &__tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-height: 98px;
    @media screen and(max-width: 1199px) {
    }
    @media screen and(max-width: 767px) {
      max-height: 120px;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;
      padding: 16px;
      width: 251px;
      height: 72px;
      background-color: #f8f8f8;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #1f1f1f;
      &_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media screen and(max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      &.active {
        border: none;
        height: 98px;
        border-radius: 8px 8px 0px 0px;
        background-color: #ffebde;
        @media screen and(max-width: 767px) {
          height: 120px;
        }
      }
      @media screen and(max-width: 1199px) {
        max-width: 200px;
        font-size: 14px;
        padding: 16px;
      }
      @media screen and(max-width: 767px) {
        height: 109px;
        font-size: 12px;
        line-height: 16px;
        padding: 8px;
        font-weight: 500;
      }
    }
    &-icon {
      margin-right: 16px;
      @media screen and(max-width: 767px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
  &__content {
    &-section {
      background-color: #ffebde;
      margin-bottom: 32px;
      padding: 24px 16px;
    }

    &-section-light {
      background-color: #fff6f0;
    }
  }
}
</style>